exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-cases-tsx": () => import("./../../../src/pages/cases.tsx" /* webpackChunkName: "component---src-pages-cases-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-diensten-seo-optimalisatie-2-tsx": () => import("./../../../src/pages/diensten/seo-optimalisatie2.tsx" /* webpackChunkName: "component---src-pages-diensten-seo-optimalisatie-2-tsx" */),
  "component---src-pages-diensten-tsx": () => import("./../../../src/pages/diensten.tsx" /* webpackChunkName: "component---src-pages-diensten-tsx" */),
  "component---src-pages-diensten-web-design-2-tsx": () => import("./../../../src/pages/diensten/web-design2.tsx" /* webpackChunkName: "component---src-pages-diensten-web-design-2-tsx" */),
  "component---src-pages-diensten-web-development-2-tsx": () => import("./../../../src/pages/diensten/web-development2.tsx" /* webpackChunkName: "component---src-pages-diensten-web-development-2-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-over-ons-tsx": () => import("./../../../src/pages/over-ons.tsx" /* webpackChunkName: "component---src-pages-over-ons-tsx" */),
  "component---src-pages-veelgestelde-vragen-tsx": () => import("./../../../src/pages/veelgestelde-vragen.tsx" /* webpackChunkName: "component---src-pages-veelgestelde-vragen-tsx" */),
  "component---src-templates-article-post-tsx": () => import("./../../../src/templates/article-post.tsx" /* webpackChunkName: "component---src-templates-article-post-tsx" */),
  "component---src-templates-case-post-tsx": () => import("./../../../src/templates/case-post.tsx" /* webpackChunkName: "component---src-templates-case-post-tsx" */),
  "component---src-templates-page-post-tsx": () => import("./../../../src/templates/page-post.tsx" /* webpackChunkName: "component---src-templates-page-post-tsx" */),
  "component---src-templates-service-post-tsx": () => import("./../../../src/templates/service-post.tsx" /* webpackChunkName: "component---src-templates-service-post-tsx" */)
}

